/*
 * Copyright 2023 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$tab-header-bg: $secondary-bg;
$accordion-header-bg-active: $go-primary;
$accordion-header-text-color: $go-primary;

.tabs {
  margin: 0;
  padding: 0 20px;
  float: left;
  list-style: none;
  border: 1px solid $border-color;
  width: 100%;
  background: $tab-header-bg;
  border-radius: $global-border-radius $global-border-radius 0 0;
  box-sizing: border-box;

  li {
    float: left;
    margin: 0;
  }
}

.tab-head {
  display: block;
  padding: 12px 20px 10px 20px;
  font-size: 13px;
  border-bottom: none;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  color: $text-color;
  font-weight: 600;
}

.active {
  border-bottom: 2px solid $go-primary;
  color: $accordion-header-bg-active;
}

.tab_container {
  border: 1px solid $border-color;
  border-top: none;
  clear: both;
  box-sizing: border-box;
}

.tab_content {
  padding: 20px;
  box-sizing: border-box;
  background: $white;
}

.tab-accordion_heading {
  display: none;
}

@media screen and (1px <= width <= 767px) {
  .tabs {
    display: none;
  }

  .tab-accordion_heading {
    border-top: 1px solid $border-color;
    margin: 0;
    padding: 10px 20px;
    display: block;
    cursor: pointer;
    font-weight: normal;
    color: $accordion-header-text-color;
    font-size: 1em;
    background: $white;
  }
}

.hide {
  display: none;
}
